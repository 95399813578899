<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="state"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        :label="title"
        label-for="state"
      >
        <v-select
          id="state"
          v-model="dataSelected"
          :class="errors.length > 0 ? 'error-select' : ''"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :filter="fuseSearch"
          label="code"
          :options="dataOptions"
          :placeholder="title"
          :disabled="readonly"
          :clearable="!readonly"
        >
          <template #option="{ code, name }">
            <span>{{ code }} - {{ name }} </span>
          </template>

          <template #selected-option="{ code }">
            <span>{{ code }} </span>
          </template>

          <template #no-options>
            Não há opções de Estado.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BFormGroup,
} from 'bootstrap-vue'
import {
  ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import Fuse from 'fuse.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'value',
    event: 'dataChange',
  },
  props: {
    value: {
      type: [Object, String],
      required: false,
      default: null,
    },
    country: {
      type: [String, Number],
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: 'Estado',
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const dataOptions = []
    const dataSelected = ''

    return {
      dataOptions,
      dataSelected,
      required,
    }
  },
  watch: {
    value: function updateProp(val) {
      this.dataSelected = val
    },
    dataSelected: function updateData(val) {
      if ((val === null) || (val === '')) {
        this.$emit('dataChange', '')
      } else {
        this.$emit('dataChange', val)
      }
    },
    country: function updateCountry(val) {
      if (val !== '') {
        this.onCreate()
      }
    },
    dataOptions: function updateOptions() {
      let clear = true
      if (this.dataSeletected) {
        this.dataOptions.forEach(data => {
          if (data.id === this.dataSeletected.id) {
            clear = false
          }
        })

        if (clear) {
          this.dataSelected = ''
        }
      }
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      let param = {}

      if (this.country !== '') {
        param = { country: this.country }
      }

      syslic
        .address
        .fetchStates(param)
        .then(response => {
          this.dataOptions = response.results
          if (!this.isOptionAvaiable(this.dataSelected.id)) {
            this.dataSelected = ''
          }
          this.$emit('ready', true)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['code', 'name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
    isOptionAvaiable(id) {
      let avaiable = false
      this.dataOptions.forEach(data => {
        if (data.id === id) {
          avaiable = true
        }
      })
      return avaiable
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
