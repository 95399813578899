<template>
  <b-card
    class="mb-2"
    no-body
  >
    <b-card-title
      class="mt-2 ml-2 mb-0"
    >
      <feather-icon
        icon="InfoIcon"
        size="23"
        class="mr-50"
      />
      <span class="d-inline">Informações</span>
    </b-card-title>
    <hr>
    <b-card-body
      class="pt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="corporate_name"
            :rules="{
              required: isFieldRequired('name')
            }"
          >
            <b-form-group
              label="Razão Social"
              label-for="corporate_name"
            >
              <b-form-input
                id="corporate_name"
                v-model="participant.name"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="readonly"
                :placeholder="readonly ? '' : 'Razão Social'"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="name_fantasy"
            :rules="{
              required: isFieldRequired('name_fantasy')
            }"
          >
            <b-form-group
              label="Nome Fantasia"
              label-for="name_fantasy"
            >
              <b-form-input
                id="name_fantasy"
                v-model="participant.name_fantasy"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="readonly"
                trim
                :placeholder="readonly ? '' : 'Razão Social'"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="cnpj"
            :rules="{
              required: isFieldRequired('cnpj'),
              min:14,
              regex:/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/
            }"
          >
            <b-form-group
              label="CNPJ"
              label-for="cnpj"
            >
              <cleave
                id="cnpj"
                v-model="participant.cnpj"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                :raw="false"
                :options="mask.options.cnpjDelimiter"
                placeholder="00.000.000/0000-00"
                :readonly="readonly"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="ie"
            :rules="{
              regex:/^[0-9]([0-9]*[.\/-][0-9]+)+$/
            }"
          >
            <b-form-group
              label="Inscrição Estadual"
              label-for="ie"
            >
              <b-form-input
                id="ie"
                v-model="participant.ie"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="readonly"
                trim
                :placeholder="readonly ? '' : 'Inscrição Estadual'"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="im"
            :rules="{
              regex:/^[0-9]([0-9]*[.\/-][0-9]+)+$/
            }"
          >
            <b-form-group
              label="Inscrição Municipal"
              label-for="im"
            >
              <b-form-input
                id="im"
                v-model="participant.im"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="readonly"
                trim
                :placeholder="readonly ? '' : 'Inscrição Municipal'"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="phone_number"
            :rules="{
              required: isFieldRequired('phone_number'),
              min: 14,
            }"
          >
            <b-form-group
              label="Telefone"
              label-for="phone_number"
            >
              <b-form-input
                id="phone_number"
                v-model="participant.phone_number"
                v-mask="getMask('phone_number')"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="readonly"
                :placeholder="readonly ? '' : '(99) 99999-9999'"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="email"
            :rules="{
              required: isFieldRequired('email'),
              email: true,
            }"
          >
            <b-form-group
              label="E-mail"
              label-for="e-mail"
            >
              <b-form-input
                id="e-mail"
                v-model="participant.email"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="readonly"
                trim
                :placeholder="readonly ? '' : 'exemplo@syslic.com.br'"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import {
  required, email, min,
} from '@validations'

import {
  ValidationProvider,
} from 'vee-validate'

import formValidation from '@core/comp-functions/forms/form-validation'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    Cleave,
  },
  model: {
    prop: 'participantValue',
    event: 'participantChange',
  },
  props: {
    participantValue: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    requiredFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      min,
      email,
      filteredOptions: [],
      limit: 10,
      selected: {
        id: 0,
      },
    }
  },
  computed: {
    participant: {
      get() {
        return this.participantValue
      },
      set(value) {
        this.$emit('participantChange', value)
      },
    },
  },
  methods: {
    getMask(field) {
      let mask = '(##) ####-####'
      if (this.participant[field]) {
        if (this.participant[field].length > 14) {
          mask = '(##) #####-####'
        }
      }

      return mask
    },
    formatCNPJ(cnpj) {
      let cnpjFormated = ''
      if (cnpj) {
        /* eslint-disable prefer-template */
        const valueUnformated = cnpj.replace(/[-./]/g, '')
        const val = valueUnformated.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/)
        cnpjFormated = !val[2] ? val[1] : val[1] + '.' + val[2] + '.' + val[3] + '/' + val[4] + (val[5] ? '-' + val[5] : '')
        /* eslint-enable prefer-template */
      }
      return cnpjFormated
    },
    isFieldRequired(field) {
      return this.requiredFields.includes(field)
    },
  },
  setup() {
    const mask = {
      options: {
        cnpjDelimiter: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
      },
    }
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      mask,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.error-select > div:first-child {
  border-color: #ea5455;
}

.card-title {
  color: $primary
}
</style>
