<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="addressType"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        label="Tipo de Logradouro"
        label-for="addressType"
      >
        <v-select
          id="addressType"
          v-model="dataSelected"
          :class="errors.length > 0 ? 'error-select' : ''"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :filter="fuseSearch"
          label="name"
          :options="dataOptions"
          :placeholder="'Tipo de Logradouro'"
          :disabled="readonly"
          :clearable="!readonly"
        >
          <template #option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #selected-option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #no-options>
            Não há opções de tipos de logradouro.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BFormGroup,
} from 'bootstrap-vue'
import {
  ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import Fuse from 'fuse.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'value',
    event: 'dataChange',
  },
  props: {
    value: {
      type: [Object, String],
      required: true,
    },
    addressType: {
      type: [String],
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const dataOptions = []
    const dataSelected = ''

    return {
      dataOptions,
      dataSelected,
      required,
    }
  },
  watch: {
    value: function updateProp(val) {
      this.dataSelected = val
    },
    dataSelected: function updateData(val) {
      if (val === null) {
        this.$emit('dataChange', '')
      } else {
        this.$emit('dataChange', val)
      }
    },
    addressType: function updateAddressType(val) {
      if (val !== '') {
        this.onCreate()
      }
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .address
        .fetchAddressTypes()
        .then(response => {
          this.dataOptions = response.results
          this.$emit('ready', true)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
