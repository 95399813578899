<template>
  <b-card no-body>
    <b-card-title
      class="mt-2 ml-2 mb-0"
    >
      <feather-icon
        icon="MapPinIcon"
        size="21"
        class="mr-50"
      />
      <span class="d-inline">Endereço</span>
    </b-card-title>
    <hr>
    <b-card-body
      class="pt-1"
    >
      <b-row>
        <b-col
          v-if="isFieldEnabled('zip_code')"
          cols="12"
          :md="isFieldEnabled('address_type') ? 3 : 4"
        >
          <validation-provider
            #default="{ errors }"
            name="zip_code"
            :rules="{
              required: isFieldRequired('zip_code'),
              min:7,
              regex:/^\d{5}-\d{3}$/
            }"
          >
            <b-form-group
              label="CEP"
              label-for="zip_code"
            >
              <cleave
                id="zip_code"
                v-model.lazy="address.zip_code"
                class="form-control"
                :raw="false"
                :options="options.cepDelimiter"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                placeholder="00000-000"
                :readonly="isViewMode"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="isFieldEnabled('address_type')"
          cols="12"
          md="3"
        >
          <address-type-select
            id="addressType"
            v-model="address.address_type_set"
            :readonly="isViewMode"
            :required-field="isFieldRequired('address_type')"
          />
        </b-col>
        <b-col
          v-if="isFieldEnabled('address')"
          cols="12"
          :md="isFieldRequired('address_type') ? 6 : 8"
        >
          <validation-provider
            #default="validationContext"
            name="address"
            :rules="{
              required: isFieldRequired('address'),
            }"
          >
            <b-form-group
              label="Endereço"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="address.address"
                :state="isViewMode ? null : getValidationState(validationContext)"
                placeholder="Endereço"
                :readonly="isViewMode"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          v-if="isFieldEnabled('number')"
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="number"
            :rules="{
              required: isFieldRequired('number'),
            }"
          >
            <b-form-group
              label="Número"
              label-for="number"
            >
              <b-form-input
                id="number"
                v-model="address.number"
                :state="isViewMode ? null : getValidationState(validationContext)"
                placeholder="000"
                :readonly="isViewMode"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="isFieldEnabled('complement')"
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="complement"
            :rules="{
              required: isFieldRequired('complement'),
            }"
          >
            <b-form-group
              label="Complemento"
              label-for="complement"
            >
              <b-form-input
                id="complement"
                v-model="address.complement"
                :state="isViewMode ? null : getValidationState(validationContext)"
                placeholder="Complemento"
                :readonly="isViewMode"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="isFieldEnabled('neighborhood_type')"
          cols="12"
          md="4"
        >
          <neighborhood-type-select
            id="neighborhoodType"
            v-model="address.neighborhood_type_set"
            :readonly="isViewMode"
            :required-field="isFieldRequired('neighborhood_type')"
          />
        </b-col>
        <b-col
          v-if="isFieldEnabled('neighborhood')"
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="neighborhood"
            :rules="{
              required: isFieldRequired('neighborhood'),
            }"
          >
            <b-form-group
              label="Bairro"
              label-for="neighborhood"
            >
              <b-form-input
                id="neighborhood"
                v-model="address.neighborhood"
                :state="isViewMode ? null : getValidationState(validationContext)"
                placeholder="Bairro"
                :readonly="isViewMode"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="isFieldEnabled('country')"
          cols="12"
          md="4"
        >
          <country-select
            id="country"
            v-model="address.country_set"
            :readonly="isViewMode"
            :required-field="isFieldRequired('country')"
          />
        </b-col>
        <b-col
          v-if="isFieldEnabled('state')"
          cols="12"
          md="4"
        >
          <state-select
            id="state"
            v-model="address.state_set"
            :readonly="isViewMode || !address.country"
            :required-field="isFieldRequired('state')"
            :country="address.country"
          />
        </b-col>

        <b-col
          v-if="isFieldEnabled('city')"
          cols="12"
          md="4"
        >
          <city-select
            id="city"
            v-model="address.city_set"
            :readonly="isViewMode || !address.state"
            :required-field="isFieldRequired('city')"
            :state="address.state"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="isFieldEnabled('region_freight')"
          cols="12"
          md="4"
        >
          <region-freight-select
            id="region_freight"
            v-model="address.region_freight_set"
            :readonly="isViewMode"
            :required-field="isFieldRequired('region_freight')"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import {
  ValidationProvider,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import CountrySelect from '@/components/address/CountrySelect.vue'
import StateSelect from '@/components/address/StateSelect.vue'
import CitySelect from '@/components/address/CitySelect.vue'
import AddressTypeSelect from '@/components/address/AddressTypeSelect.vue'
import NeighborhoodTypeSelect from '@/components/address/NeighborhoodTypeSelect.vue'
import RegionFreightSelect from '@/components/transport/RegionFreightSelect.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    Cleave,
    CountrySelect,
    StateSelect,
    CitySelect,
    AddressTypeSelect,
    NeighborhoodTypeSelect,
    RegionFreightSelect,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    isViewMode: {
      type: Boolean,
      required: true,
    },
    requiredFields: {
      type: Array,
      required: true,
    },
    enabledFields: {
      type: Array,
      required: false,
      default() {
        return [
          'zip_code',
          'address',
          'number',
          'complement',
          'neighborhood',
          'state',
          'city',
          'country',
        ]
      },
    },
  },
  data() {
    return {
      options: {
        cepDelimiter: {
          delimiters: ['-'],
          blocks: [5, 3],
          uppercase: true,
          required: true,
        },
      },
    }
  },
  watch: {
    'address.country_set': function updateCountry(val) {
      if (val) {
        this.address.country = val.id
        this.address.country_set = val
      } else {
        this.address.country = ''
        this.address.country_set = ''
        this.address.state = ''
        this.address.state_set = ''
        this.address.city = ''
        this.address.city_set = ''
      }
    },
    'address.state_set': function updateState(val) {
      if (val) {
        this.address.state = val.id
      } else {
        this.address.state = ''
        this.address.state_set = ''
        this.address.city = ''
        this.address.city_set = ''
      }
    },
    'address.city_set': function updateCity(val) {
      if (val) {
        this.address.city = val.id
      } else {
        this.address.city = ''
      }
    },
    'address.address_type_set': function updateAddressType(val) {
      if (val) {
        this.address.address_type = val.id
        this.address.address_type_set = val
      } else {
        this.address.address_type = ''
        this.address.address_type_set = ''
      }
    },
    'address.neighborhood_type_set': function updateNeighborhoodType(val) {
      if (val) {
        this.address.neighborhood_type = val.id
      } else {
        this.address.neighborhood_type = ''
      }
    },
    'address.region_freight_set': function updateRegionFreight(val) {
      if (val) {
        this.address.region_freight = val.id
      } else {
        this.address.region_freight = ''
      }
    },
  },
  methods: {
    isFieldRequired(field) {
      return this.requiredFields.includes(field)
    },
    isFieldEnabled(field) {
      return this.enabledFields.includes(field)
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
