<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="10"
        >
          <div
            v-if="hasPermissionToUpdate"
          >
            <b-row
              v-if="isViewMode"
            >
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="isViewMode"
                    id="bnt-edit"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :disabled="!hasPermissionToDelete"
                    @click="showConfirmOrCancelDelete(clientId)"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>

          <validation-observer
            #default="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <b-form
              class="mt-1"
              @submit.prevent="handleSubmit(onSubmit)"
            >

              <participant-edit-card
                v-model="client"
                :required-fields="requiredFieldsParticipant"
                :readonly="isViewMode"
              />

              <address-edit-card
                :address="client"
                :is-view-mode="isViewMode"
                :required-fields="requiredFieldsAddress"
                :enabled-fields="enabledFieldsAddress"
              />

              <contact-card
                v-if="!isCreateMode"
                :client="clientId"
                :readonly="isViewMode"
              />

              <b-row
                class="mt-2"
              >
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    v-if="!isViewMode"
                    id="btn-save"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    id="itemCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to=" { name: 'client' } "
                  >
                    Voltar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import {
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  ValidationObserver,
} from 'vee-validate'

import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import SvgIcon from '@jamescoyle/vue-icon'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import AddressEditCard from '@/components/address/AddressEditCard.vue'
import ParticipantEditCard from '@/components/participant/ParticipantEditCard.vue'
import ContactCard from '@/components/contact/ContactCard.vue'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    ValidationObserver,
    ParticipantEditCard,
    AddressEditCard,
    BDropdown,
    BDropdownItem,
    SpinLoader,
    ContactCard,
    SvgIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    const requiredFieldsAddress = [
      'region_freight',
    ]

    const enabledFieldsAddress = [
      'zip_code',
      'address',
      'number',
      'complement',
      'neighborhood',
      'state',
      'city',
      'country',
      'region_freight',
    ]

    const requiredFieldsParticipant = [
      'name',
      'cnpj',
    ]

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.client)

    let isViewMode = true
    let isCreateMode = false

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    }

    const client = {
      id: '',
      address: '',
      address_type: '',
      address_type_set: '',
      neighborhood: '',
      neighborhood_type: '',
      neighborhood_type_set: '',
      complement: '',
      number: '',
      city: '',
      city_set: '',
      state: '',
      state_set: '',
      country: 1,
      country_set: {
        id: 1,
        name: 'Brasil',
        code: '1058',
      },
      zip_code: '',
      region_freight: '',
      region_freight_set: '',
    }

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('client')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      isViewMode,
      isCreateMode,
      refFormObserver,
      getValidationState,
      text_error: '',
      client,
      requiredFieldsAddress,
      enabledFieldsAddress,
      requiredFieldsParticipant,
      clientId: null,
      isLoaded,
      options: {
        price: {
          numeral: true,
          numeralDecimalScale: 2,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
      syncLoad,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.client.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.client.can_delete
    },
  },
  created() {
    const idToUpdate = router.currentRoute.params.id
    this.clientId = router.currentRoute.params.id
    if (idToUpdate !== undefined) {
      syslic
        .client
        .fetchClient(router.currentRoute.params.id)
        .then(response => {
          this.client = response.data
          this.syncLoad.ready('client')
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.syncLoad.ready('client')
        })
    } else {
      this.syncLoad.ready('client')
    }
  },
  methods: {
    adjustDecimalPlaces(field, places) {
      const decimalReference = 10 * places
      const num = this.client[field]

      if (num !== '') {
        this.client[field] = ((num * decimalReference) / decimalReference).toFixed(places)
      }
    },
    createClient() {
      syslic
        .client
        .addClient(this.client)
        .then(response => {
          this.clientId = response.data.id
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Órgão adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(error => {
          let variantType = 'danger'
          this.text_error = 'Não foi possível adicionar o Órgão, por favor entre em contato com o administrador do sistema.'
          if (error.data.detail) {
            if (error.data.detail === 'client already exists.') {
              this.text_error = 'O Órgão com este CNPJ já existe.'
              variantType = 'warning'
            }
          } else if (error.data.cnpj) {
            if (error.data.cnpj[0].includes('is not valid cnpj')) {
              this.text_error = 'Este CNPJ não é válido.'
              variantType = 'warning'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar Órgão.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: variantType,
              timeout: false,
            },
          })
        })
    },
    updateClient() {
      syslic
        .client
        .updateClient(this.client.id, this.client)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Órgão atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o Órgão.',
              text:
                'Não foi possível atualizar o órgão, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onSubmit() {
      if (this.isCreateMode) {
        this.createClient()
      } else {
        this.updateClient()
      }
    },
    deleteClient(id) {
      syslic
        .client
        .deleteClient(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Órgão removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'client' })
        })
        .catch(error => {
          let textError = 'Não foi possível remover o órgão do servidor, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Este órgão é referenciado em alguma licitação, por este motivo não será possível exclui-lo.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover órgão.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover esse órgão?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteClient(item)
          }
        })
    },
    enableEdition() {
      router.push({ name: 'client-edit' })
    },
    disableEdition() {
      if (this.isCreateMode) {
        router.push({ name: 'client' })
      } else {
        router.push({
          name: 'client-detail',
          params: {
            id: this.clientId,
          },
        })
          .catch(() => {})
      }

      this.isCreateMode = false
    },
  },
  setup() {
    const toast = useToast()
    const mask = {
      options: {
        cepDelimiter: {
          delimiters: ['-'],
          blocks: [5, 3],
          uppercase: true,
          required: true,
        },
        ieDelimiter: {
          delimiters: ['.', '.', '.'],
          blocks: [3, 3, 3, 3],
          uppercase: true,
        },
        cnpjDelimiter: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
      },
    }

    return {
      toast,
      mask,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.disabledEdition .input-group-text{
  background-color: #efefef;
}

input::placeholder {
  color: #b9b9c3 !important;
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
