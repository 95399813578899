var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-title',{staticClass:"mt-2 ml-2 mb-0"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon","size":"23"}}),_c('span',{staticClass:"d-inline"},[_vm._v("Informações")])],1),_c('hr'),_c('b-card-body',{staticClass:"pt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"corporate_name","rules":{
            required: _vm.isFieldRequired('name')
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Razão Social","label-for":"corporate_name"}},[_c('b-form-input',{attrs:{"id":"corporate_name","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"readonly":_vm.readonly,"placeholder":_vm.readonly ? '' : 'Razão Social'},model:{value:(_vm.participant.name),callback:function ($$v) {_vm.$set(_vm.participant, "name", $$v)},expression:"participant.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"name_fantasy","rules":{
            required: _vm.isFieldRequired('name_fantasy')
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Nome Fantasia","label-for":"name_fantasy"}},[_c('b-form-input',{attrs:{"id":"name_fantasy","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"readonly":_vm.readonly,"trim":"","placeholder":_vm.readonly ? '' : 'Razão Social'},model:{value:(_vm.participant.name_fantasy),callback:function ($$v) {_vm.$set(_vm.participant, "name_fantasy", $$v)},expression:"participant.name_fantasy"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"cnpj","rules":{
            required: _vm.isFieldRequired('cnpj'),
            min:14,
            regex:/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"cnpj"}},[_c('cleave',{class:errors.length > 0 ? 'form-control is-invalid' : 'form-control',attrs:{"id":"cnpj","raw":false,"options":_vm.mask.options.cnpjDelimiter,"placeholder":"00.000.000/0000-00","readonly":_vm.readonly},model:{value:(_vm.participant.cnpj),callback:function ($$v) {_vm.$set(_vm.participant, "cnpj", $$v)},expression:"participant.cnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"ie","rules":{
            regex:/^[0-9]([0-9]*[.\/-][0-9]+)+$/
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Inscrição Estadual","label-for":"ie"}},[_c('b-form-input',{attrs:{"id":"ie","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"readonly":_vm.readonly,"trim":"","placeholder":_vm.readonly ? '' : 'Inscrição Estadual'},model:{value:(_vm.participant.ie),callback:function ($$v) {_vm.$set(_vm.participant, "ie", $$v)},expression:"participant.ie"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"im","rules":{
            regex:/^[0-9]([0-9]*[.\/-][0-9]+)+$/
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Inscrição Municipal","label-for":"im"}},[_c('b-form-input',{attrs:{"id":"im","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"readonly":_vm.readonly,"trim":"","placeholder":_vm.readonly ? '' : 'Inscrição Municipal'},model:{value:(_vm.participant.im),callback:function ($$v) {_vm.$set(_vm.participant, "im", $$v)},expression:"participant.im"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"phone_number","rules":{
            required: _vm.isFieldRequired('phone_number'),
            min: 14,
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Telefone","label-for":"phone_number"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getMask('phone_number')),expression:"getMask('phone_number')"}],attrs:{"id":"phone_number","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"readonly":_vm.readonly,"placeholder":_vm.readonly ? '' : '(99) 99999-9999'},model:{value:(_vm.participant.phone_number),callback:function ($$v) {_vm.$set(_vm.participant, "phone_number", $$v)},expression:"participant.phone_number"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":{
            required: _vm.isFieldRequired('email'),
            email: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"E-mail","label-for":"e-mail"}},[_c('b-form-input',{attrs:{"id":"e-mail","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"readonly":_vm.readonly,"trim":"","placeholder":_vm.readonly ? '' : 'exemplo@syslic.com.br'},model:{value:(_vm.participant.email),callback:function ($$v) {_vm.$set(_vm.participant, "email", $$v)},expression:"participant.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }