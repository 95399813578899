<template>
  <div>
    <b-card no-body>
      <b-card-title
        class="mt-2 ml-2 mb-1"
      >
        <svg-icon
          type="mdi"
          size="26"
          :path="mdiCardAccountPhone"
        />
        <span class="d-inline ml-1">Contatos</span>
      </b-card-title>
      <b-card-body
        class="p-0"
        :class="{ 'contactsFull': !readonly }"
      >
        <b-table
          class="position-relative"
          :class="{
            'mb-0': ((data.length === 0) || readonly),
            'mb-2': ((data.length > 0)),
            'pb-3': ((data.length > 0) && !readonly),
            'contactsFull': ((data.length === 0) && !readonly),
          }"
          primary-key="id"
          responsive
          show-empty
          striped
          hover
          empty-text="Não há contatos cadastrados."
          :items="data"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :busy="isLoading"
          @row-clicked="edit"
        >
          <template #table-busy>
            <div class="text-center my-3">
              <b-spinner
                variant="info"
                class="align-middle mb-1"
              />
              <br>
              <strong class="pt-2">Carregando...</strong>
            </div>
          </template>
          <template #cell(name)="field">
            <span class="text-nowrap">
              {{ field.item.name }}
            </span>
          </template>

          <template #cell(phone_number)="field">
            <div
              v-if="field.item.phone_number"
            >
              {{ field.item.phone_number }}
            </div>
            <div v-else>
              <span class="font-weight-bold">-</span>
            </div>
          </template>

          <template #cell(sector)="field">
            <div
              v-if="field.item.sector"
            >
              {{ field.item.sector }}
            </div>
            <div v-else>
              <span class="font-weight-bold">-</span>
            </div>
          </template>

          <template #cell(position)="field">
            <div
              v-if="field.item.position"
            >
              {{ field.item.position }}
            </div>
            <div v-else>
              <span class="font-weight-bold">-</span>
            </div>
          </template>

          <template #cell(field_actions)="field">
            <div v-if="!readonly">
              <b-button
                class="btn-icon rounded-circle"
                variant="flat-info"
                size="md"
                @click="showConfirmOrCancelDelete(field.item)"
              >
                <svg-icon
                  type="mdi"
                  size="18"
                  :path="mdiTrashCanOutline"
                />
              </b-button>
            </div>
          </template>
        </b-table>

        <div v-if="!readonly">
          <b-button
            id="addItemBtn"
            v-b-tooltip.hover
            v-b-modal.idModalContact
            class="btn-icon rounded-circle m-2"
            variant="primary"
            size="md"
            title="Adicionar Contato"
          >
            <svg-icon
              type="mdi"
              :path="mdiPlus"
            />
          </b-button>
        </div>
      </b-card-body>
    </b-card>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalContact"
        ref="modalContact"
        no-close-on-backdrop
        no-close-on-esc
        centered
        cancel-variant="outline-primary"
        :hide-footer="readonly"
        :ok-disabled="invalid"
        @ok="handleSubmit(save)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Cadastro de Contato
        </template>
        <template #modal-cancel>
          Cancelar
        </template>
        <template #modal-ok>
          Salvar
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="name"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="value.name"
                    name="name"
                    placeholder="Nome"
                    :readonly="readonly"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="phone_number"
              >
                <b-form-group
                  label="Telefone"
                  label-for="phone_number"
                >
                  <b-form-input
                    id="phone_number"
                    v-model="value.phone_number"
                    v-mask="getMask('phone_number')"
                    :state="readonly ? null : getValidationState(validationContext)"
                    :readonly="readonly"
                    :placeholder="readonly ? '' : '(99) 99999-9999'"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="sector"
              >
                <b-form-group
                  label="Setor"
                  label-for="sector"
                >
                  <b-form-input
                    id="sector"
                    v-model="value.sector"
                    name="sector"
                    :placeholder="readonly ? '' : 'Setor'"
                    :readonly="readonly"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="position"
              >
                <b-form-group
                  label="Cargo"
                  label-for="position"
                >
                  <b-form-input
                    id="position"
                    v-model="value.position"
                    name="position"
                    :placeholder="readonly ? '' : 'Cargo'"
                    :readonly="readonly"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BForm,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BButton,
  BTable,
  BModal,
  BSpinner,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiCardAccountPhone,
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  required,
  confirmed,
} from '@validations'

import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BButton,
    BTable,
    BModal,
    BSpinner,
    SvgIcon,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  filters: {
    truncate,
  },
  props: {
    company: {
      type: [String, Number],
      required: false,
      default: null,
    },
    supplier: {
      type: [String, Number],
      required: false,
      default: null,
    },
    client: {
      type: [String, Number],
      required: false,
      default: null,
    },
    carrier: {
      type: [String, Number],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const sortBy = 'name'
    const isSortDirDesc = false

    const tableColumns = [
      {
        key: 'name',
        label: 'Nome',
        thClass: 'contactHeader',
      },
      {
        key: 'phone_number',
        label: 'Telefone',
        thClass: 'contactHeader',
      },
      {
        key: 'sector',
        label: 'Setor',
        thClass: 'contactHeader',
      },
      {
        key: 'position',
        label: 'Cargo',
        thClass: 'contactHeader',
      },
      {
        key: 'field_actions',
        label: '',
        thClass: 'contactHeader',
        thStyle: { width: '5%' },
      },
    ]

    const data = []

    const valueEmpty = {
      name: '',
      phone_number: '',
      sector: '',
      position: '',
    }

    const value = JSON.parse(JSON.stringify(valueEmpty))

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('contact')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const isAdd = true
    const fetchId = ''

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    return {
      required,
      confirmed,
      refFormObserver,
      getValidationState,
      sortBy,
      isSortDirDesc,
      tableColumns,
      data,
      value,
      valueEmpty,
      isLoading,
      syncLoad,
      isAdd,
      fetchId,
    }
  },
  watch: {
    supplier: 'fetchData',
    client: 'fetchData',
    company: 'fetchData',
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.isLoading = true

      const fetchPending = Math.random()
      this.fetchId = fetchPending

      const params = {}

      if (this.client) {
        params.client = this.client
      }

      if (this.company) {
        params.company = this.company
      }

      if (this.supplier) {
        params.supplier = this.supplier
      }

      if (this.carrier) {
        params.carrier = this.carrier
      }

      syslic
        .contact
        .fetchContacts(params)
        .then(response => {
          if (fetchPending === this.fetchId) {
            this.data = response.data.results
            this.syncLoad.ready('contact')
          }
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados de contatos.',
                text: 'Não foi possível ler os dados dos contatos, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.syncLoad.ready('contact')
          }
        })
    },
    deleteClient(item) {
      syslic
        .contact
        .deleteContact(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Contato removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.data.splice(this.data.indexOf(item), 1)
        })
        .catch(error => {
          let textError = 'Não foi possível remover este contato, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Este contato é referenciado em alguma licitação, por este motivo não será possível remove-lo.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover contato.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você deseja remover o contato.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteClient(item)
          }
        })
    },
    add(val) {
      syslic
        .contact
        .addContact(val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Contato adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          const textError = 'Não foi possível adicionar este contato, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar contato.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    update(id, val) {
      syslic
        .contact
        .updateContact(id, val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Contato atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          const textError = 'Não foi possível atualizar este contato, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar contato.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    edit(item) {
      if (!this.readonly) {
        this.value = JSON.parse(JSON.stringify(item))
        this.isAdd = false
        this.$refs.modalContact.show()
      }
    },
    save() {
      if (this.client) {
        this.value.client = this.client
      }

      if (this.company) {
        this.value.company = this.company
      }

      if (this.supplier) {
        this.value.supplier = this.supplier
      }

      if (this.carrier) {
        this.value.carrier = this.carrier
      }

      if (this.client || this.supplier || this.company || this.carrier) {
        if (this.isAdd) {
          this.add(this.value)
        } else {
          this.update(this.value.id, this.value)
        }

        this.isAdd = true
      } else {
        this.$swal({
          title: 'Erro',
          text: 'Nenhum orgão, fonecedor ou empresa foi associada ao contato, favor entrar em contato com o administrador do sistema.',
          icon: 'error',
          showConfirmButton: false,
          timer: 4000,
        })
      }
    },
    cancel() {
      this.value = JSON.parse(JSON.stringify(this.valueEmpty))
      this.isAdd = true
    },
    getMask(field) {
      let mask = '(##) ####-####'
      if (this.value[field]) {
        if (this.value[field].length > 14) {
          mask = '(##) #####-####'
        }
      }

      return mask
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiCardAccountPhone,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.smallField {
  width: 5px !important;
}

.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}

#idModalContact .vc-slider {
  width: 100% !important;
}

#addItemBtn {
  position: absolute;
  right: 0;
  bottom: 0;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/base/core/colors/palette-variables.scss";

.card-title {
  color: $primary
}

.contactsFull {
  min-height: 15rem;
}

.contactsFull > .table {
  min-height: 15rem;
}

#idContactTable {
  padding-bottom: 3rem;
}

.contactHeader {
  background-color: #ffffff !important;
  border-bottom: 0 !important;
}

.contactHeader * {
  color: #5e5873
}

#idModalContact .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;

  .btn{
    min-width: 107px;
  }
}

</style>
